import React from "react";
import { Helmet } from "react-helmet";
import { useRoutesContext } from "../RoutesProvider";
import { useLanguageContext } from "../LanguageProvider";

const qualifyUrl = (url: string, baseUrl: string): string => {
	return `${baseUrl}${url}`;
};

/**
 * Function to add Root meta tags to the header to all pages
 */
export default function () {
	const { lang } = useLanguageContext();
	const { route, slugs, pageTitles, pageDescriptions, baseUrl } = useRoutesContext();

	const seoLinks = [];
	if (route && slugs[route.id]) {
		// Add canonical url
		if (slugs[route.id][lang]) {
			seoLinks.push({
				rel: "canonical",
				href: qualifyUrl(slugs[route.id][lang], baseUrl),
			});
		}
		// Add x-default-lang
		if (slugs[route.id]["es"]) {
			seoLinks.push({
				rel: "alternate",
				hrefLang: "x-default",
				href: qualifyUrl(slugs[route.id]["es"], baseUrl),
			});
		}
		// Add specific lang alternates grouping them by language
		const alternates: { [lang: string]: { rel: string; hrefLang: string; href: string } } = {};
		for (const page of route.pages) {
			if (slugs[route.id][page.lang]) {
				alternates[page.lang] = {
					rel: "alternate",
					hrefLang: page.lang,
					href: qualifyUrl(slugs[route.id][page.lang], baseUrl),
				};
			}
		}
		seoLinks.push(...Object.values(alternates));
	}

	const pageTitle = (route && pageTitles[route.id] && pageTitles[route.id][lang]) || "Keynua";
	const pageDescription = (route && pageDescriptions[route.id] && pageDescriptions[route.id][lang]) || "";

	return (
		<Helmet
			htmlAttributes={{ lang }}
			title={pageTitle}
			link={[...seoLinks]}
			meta={[
				{ name: `viewport`, content: "width=device-width,initial-scale=1,maximum-scale=1,shrink-to-fit=no" },
				...(pageDescription ? [{ name: "description", content: pageDescription }] : []),
				{ httpEquiv: "x-ua-compatible", content: "ie=edge" },
			]}
		>
			<style type="text/css">{`
		html { scroll-behavior: smooth; }
		.bookmark:after {content:"";display:block;padding-top:66px;}
		.ficon-sm { height: 16px !important; }
		.ficon-md { height: 32px !important; }
		.ficon-lg { height: 42px !important; }
		.ficon-fs { height: 1em; vertical-align: middle!important; }
		`}</style>
		</Helmet>
	);
}
