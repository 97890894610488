import React from "react";
import { BaseStyles } from "@keynua/frontier/react";
import { RoutesProvider } from "../RoutesProvider";
import { LanguageProvider } from "../LanguageProvider";
import { MetadataProvider } from "../MetadataProvider";
import SEO from "./SEO";
import { KeynuaDataProvider } from "../KeynuaDataProvider";

export function RootLayout({ children, ...props }: any) {
	return (
		<MetadataProvider>
			<LanguageProvider lang={props.pageContext.lang}>
				<RoutesProvider routeId={props.pageContext.routeId}>
					<KeynuaDataProvider>
						<SEO />
						<BaseStyles>{children}</BaseStyles>
					</KeynuaDataProvider>
				</RoutesProvider>
			</LanguageProvider>
		</MetadataProvider>
	);
}
