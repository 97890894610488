import * as Sentry from "@sentry/browser";
import axios, { AxiosRequestConfig } from "axios";
import { ApiErrorResponse } from "@src/apis/_types";
import { HttpRequestError } from "@src/apis/_errors";

const apiClient = axios.create();

export function configureApiClient(config: AxiosRequestConfig) {
	apiClient.defaults.baseURL = config.baseURL;
}

apiClient.interceptors.response.use(undefined, (error: ApiErrorResponse) => {
	Sentry.withScope((scope: any) => {
		const newError = new HttpRequestError(error);
		scope.setLevel("warning");
		scope.setTag("http.request", "yes");
		if (error.response) {
			scope.setContext("http request", {
				config: {
					baseURL: error.response.config.baseURL,
					url: error.response.config.url,
					data: error.response.config.data,
				},
				body: error.response.data,
				status: error.response.status,
			});
			Sentry.captureException(newError);
		} else {
			Sentry.captureException(newError);
		}
	});
	return Promise.reject(error);
});

export default apiClient;
