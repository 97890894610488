/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { RootLayout, ChatbotScript } from "./src/bootstrap";

/**
 * Wrap all pages in the browser with a global layout that
 * includes providers and base styles
 *
 * @param {*} param0
 */
export const wrapPageElement = ({ element, props }) => {
	return (
		<RootLayout {...props}>
			{element}
			<ChatbotScript />
		</RootLayout>
	);
};
