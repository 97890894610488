exports.components = {
  "component---src-page-account-request-index-tsx": () => import("./../../../src/page/AccountRequest/index.tsx" /* webpackChunkName: "component---src-page-account-request-index-tsx" */),
  "component---src-page-blockchain-eth-merkle-index-tsx": () => import("./../../../src/page/Blockchain/EthMerkle/index.tsx" /* webpackChunkName: "component---src-page-blockchain-eth-merkle-index-tsx" */),
  "component---src-page-electronic-signature-index-tsx": () => import("./../../../src/page/ElectronicSignature/index.tsx" /* webpackChunkName: "component---src-page-electronic-signature-index-tsx" */),
  "component---src-page-features-index-tsx": () => import("./../../../src/page/Features/index.tsx" /* webpackChunkName: "component---src-page-features-index-tsx" */),
  "component---src-page-home-index-tsx": () => import("./../../../src/page/Home/index.tsx" /* webpackChunkName: "component---src-page-home-index-tsx" */),
  "component---src-page-identification-index-tsx": () => import("./../../../src/page/Identification/index.tsx" /* webpackChunkName: "component---src-page-identification-index-tsx" */),
  "component---src-page-landing-pages-bcp-index-tsx": () => import("./../../../src/page/LandingPages/Bcp/index.tsx" /* webpackChunkName: "component---src-page-landing-pages-bcp-index-tsx" */),
  "component---src-page-landing-pages-bcp-terms-tsx": () => import("./../../../src/page/LandingPages/Bcp/terms.tsx" /* webpackChunkName: "component---src-page-landing-pages-bcp-terms-tsx" */),
  "component---src-page-landing-pages-caja-trujillo-index-tsx": () => import("./../../../src/page/LandingPages/CajaTrujillo/index.tsx" /* webpackChunkName: "component---src-page-landing-pages-caja-trujillo-index-tsx" */),
  "component---src-page-landing-pages-credit-claim-ccl-tsx": () => import("./../../../src/page/LandingPages/CreditClaim/ccl.tsx" /* webpackChunkName: "component---src-page-landing-pages-credit-claim-ccl-tsx" */),
  "component---src-page-landing-pages-credit-claim-retriever-tsx": () => import("./../../../src/page/LandingPages/CreditClaim/retriever.tsx" /* webpackChunkName: "component---src-page-landing-pages-credit-claim-retriever-tsx" */),
  "component---src-page-landing-pages-mi-banco-index-tsx": () => import("./../../../src/page/LandingPages/MiBanco/index.tsx" /* webpackChunkName: "component---src-page-landing-pages-mi-banco-index-tsx" */),
  "component---src-page-legal-privacy-policy-index-tsx": () => import("./../../../src/page/Legal/PrivacyPolicy/index.tsx" /* webpackChunkName: "component---src-page-legal-privacy-policy-index-tsx" */),
  "component---src-page-legal-terms-and-conditions-index-tsx": () => import("./../../../src/page/Legal/TermsAndConditions/index.tsx" /* webpackChunkName: "component---src-page-legal-terms-and-conditions-index-tsx" */),
  "component---src-page-pay-index-tsx": () => import("./../../../src/page/Pay/index.tsx" /* webpackChunkName: "component---src-page-pay-index-tsx" */),
  "component---src-page-pay-transaction-index-tsx": () => import("./../../../src/page/PayTransaction/index.tsx" /* webpackChunkName: "component---src-page-pay-transaction-index-tsx" */),
  "component---src-page-payment-form-index-tsx": () => import("./../../../src/page/PaymentForm/index.tsx" /* webpackChunkName: "component---src-page-payment-form-index-tsx" */),
  "component---src-page-plans-index-tsx": () => import("./../../../src/page/Plans/index.tsx" /* webpackChunkName: "component---src-page-plans-index-tsx" */),
  "component---src-page-use-cases-index-tsx": () => import("./../../../src/page/UseCases/index.tsx" /* webpackChunkName: "component---src-page-use-cases-index-tsx" */),
  "component---src-page-verificar-kades-index-tsx": () => import("./../../../src/page/VerificarKades/index.tsx" /* webpackChunkName: "component---src-page-verificar-kades-index-tsx" */),
  "component---src-page-verification-index-tsx": () => import("./../../../src/page/Verification/index.tsx" /* webpackChunkName: "component---src-page-verification-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-pt-404-tsx": () => import("./../../../src/pages/pt/404.tsx" /* webpackChunkName: "component---src-pages-pt-404-tsx" */)
}

