import React from "react";
import { useMetadataContext } from "../MetadataProvider";

// Define tu componente ChatbotScript
export const ChatbotScript = () => {
	const { cspNonce } = useMetadataContext();

	React.useEffect(() => {
		// Crear un nuevo script element
		var script = document.createElement("script");
		script.nonce = cspNonce;
		script.type = "text/javascript";
		script.async = true;

		// Contenido del script
		script.innerHTML = `(function (w, d) {var iapp = d.createElement("chatbot-root");d.getElementsByTagName("body")[0].appendChild(iapp);w.Clientify_botId = "138075";w.Clientify_botUserId = "29393";var h = d.head || d.getElementsByTagName("head")[0];var s = d.createElement("script");s.setAttribute("type", "text/javascript");s.setAttribute("src", "https://api.clientify.net/static/js/chatbot/launcher/launcher.js");h.appendChild(s);})(window, document);`;

		// Agregar el script al elemento body
		document.body.appendChild(script);
	}, []);

	return null; // Este componente no necesita renderizar nada visible
};
