import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export interface IRoute {
	id: string;
	component: string;
	pages: Array<{
		lang: string;
		slug: string;
		title: string;
	}>;
}

/**
 * The RoutesContext
 */
export interface IRoutesContext {
	/**
	 * The baseUrl of the site https://www.keynua.com
	 */
	baseUrl: string;
	/**
	 * The current route
	 */
	route?: IRoute;
	/**
	 * All of the routes indexed by id
	 */
	routes: {
		[routeId: string]: IRoute;
	};
	/**
	 * The slugs by routeId and language
	 */
	slugs: {
		[routeId: string]: {
			[language: string]: string;
		};
	};
	/**
	 * The page titles by routeId and language
	 */
	pageTitles: {
		[routeId: string]: {
			[language: string]: string;
		};
	};
	/**
	 * The page descriptions by routeId and language
	 */
	pageDescriptions: {
		[routeId: string]: {
			[language: string]: string;
		};
	};
}

/**
 * Language context so that we can have the current
 * language and supported languages anywhere in the application
 */
const RoutesContext = React.createContext<IRoutesContext>({
	baseUrl: "/",
	routes: {},
	slugs: {},
	pageTitles: {},
	pageDescriptions: {},
});
export { RoutesContext };

/**
 * Helper function to get the useContext hook
 * already configured with the RoutesContext
 */
export function useRoutesContext(): IRoutesContext {
	return useContext(RoutesContext);
}

/**
 * Routes provider to wrap pages
 *
 * @param param0
 */
export function RoutesProvider({ routeId, children }: { routeId: string; children?: any }) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					webEndpoint
					languages {
						id
						prefix
						pageTitle
					}
					routes {
						id
						component
						pages {
							lang
							slug
							title
							description
						}
					}
				}
			}
		}
	`);
	const langPrefix: { [language: string]: string } = {};
	const langPageTitles: { [language: string]: string } = {};
	for (const lang of data.site.siteMetadata.languages) {
		langPrefix[lang.id] = lang.prefix;
		langPageTitles[lang.id] = lang.pageTitle;
	}
	const routes: IRoutesContext["routes"] = {};
	const slugs: IRoutesContext["slugs"] = {};
	const pageTitles: IRoutesContext["pageTitles"] = {};
	const pageDescriptions: IRoutesContext["pageDescriptions"] = {};
	for (const route of data.site.siteMetadata.routes) {
		routes[route.id] = route;
		const slugByLang: { [languageId: string]: string } = {};
		const titlesByLang: { [languageId: string]: string } = {};
		const descriptionsByLang: { [languageId: string]: string } = {};
		for (const page of route.pages) {
			slugByLang[page.lang] = `/${langPrefix[page.lang]}${page.slug}/`;
			if (slugByLang[page.lang].startsWith("//")) {
				slugByLang[page.lang] = `${langPrefix[page.lang]}${page.slug}/`;
			}
			if (slugByLang[page.lang].endsWith("//")) {
				slugByLang[page.lang] = slugByLang[page.lang].substr(0, slugByLang[page.lang].length - 1);
			}
			titlesByLang[page.lang] = (page.title || "").replace("%s", langPageTitles[page.lang] || "");
			descriptionsByLang[page.lang] = page.description || "";
		}
		slugs[route.id] = slugByLang;
		pageTitles[route.id] = titlesByLang;
		pageDescriptions[route.id] = descriptionsByLang;
	}
	return (
		<RoutesContext.Provider
			value={{
				baseUrl: data.site.siteMetadata.webEndpoint,
				route: routes[routeId],
				routes,
				slugs,
				pageTitles,
				pageDescriptions,
			}}
		>
			{children}
		</RoutesContext.Provider>
	);
}
