import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { configureApiClient } from "@src/apis";
import * as Sentry from "@sentry/browser";

/**
 * The MetadataContext
 */
export interface IMetadataContext {
	webEndpoint: string;
	apiEndpoint: string;
	appEndpoint: string;
	signEndpoint: string;
	cspNonce?: string;
	googleRecaptchaSiteKey: string;
	mandrillApiKey: string;
	envName: string;
	urlLibroReclamaciones: string;
}

/**
 * Language context so that we can have the current
 * language and supported languages anywhere in the application
 */
const MetadataContext = React.createContext<IMetadataContext>({
	webEndpoint: "https://www.keynua.com",
	apiEndpoint: "https://api.keynua.com",
	appEndpoint: "https://app.keynua.com",
	signEndpoint: "https://sign.keynua.com",
	googleRecaptchaSiteKey: "",
	mandrillApiKey: "",
	urlLibroReclamaciones: "",
	envName: "dev",
});
export { MetadataContext };

/**
 * Helper function to get the useContext hook
 * already configured with the MetadataContext
 */
export function useMetadataContext(): IMetadataContext {
	return useContext(MetadataContext);
}

/**
 * Metadata provider to wrap pages
 *
 * @param param0
 */
export function MetadataProvider({ children }: { children?: any }) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					webEndpoint
					apiEndpoint
					appEndpoint
					signEndpoint
					cspNonce
					envName
					googleRecaptchaSiteKey
					urlLibroReclamaciones
					sentryDsn
					mandrillApiKey
				}
			}
		}
	`);

	// Configure axios
	configureApiClient({
		baseURL: data.site.siteMetadata.apiEndpoint,
	});

	// Configure sentry
	useEffect(() => {
		if (data.site.siteMetadata.sentryDsn) {
			Sentry.init({
				dsn: data.site.siteMetadata.sentryDsn,
				environment: data.site.siteMetadata.envName,
				enabled: true,
				debug: data.site.siteMetadata.envName === "local",
				beforeSend(event, hint) {
					const error = hint && (hint.originalException as any);
					if (error && error.isAxiosError) return null;
					return event;
				},
			});
		}
	}, []);

	return (
		<MetadataContext.Provider
			value={{
				webEndpoint: data.site.siteMetadata.webEndpoint,
				apiEndpoint: data.site.siteMetadata.apiEndpoint,
				appEndpoint: data.site.siteMetadata.appEndpoint,
				signEndpoint: data.site.siteMetadata.signEndpoint,
				cspNonce: data.site.siteMetadata.cspNonce,
				envName: data.site.siteMetadata.envName,
				urlLibroReclamaciones: data.site.siteMetadata.urlLibroReclamaciones,
				googleRecaptchaSiteKey: data.site.siteMetadata.googleRecaptchaSiteKey,
				mandrillApiKey: data.site.siteMetadata.mandrillApiKey,
			}}
		>
			{children}
		</MetadataContext.Provider>
	);
}
