import { ApiErrorResponse } from "@src/apis/_types";

export class HttpRequestError extends Error {
	stack?: string;

	constructor(error: ApiErrorResponse) {
		super(error.message);
		this.stack = error.stack;
	}
}
