import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { IPack } from "../../page/Plans/Packs/Pack";

/**
 * The KeynuaDataContext
 */
export interface IKeynuaDataContext {
	packs: IPack[];
	getPackById: (id: string) => IPack | undefined;
}

/**
 * KeynuaData node context
 */
const KeynuaDataContext = React.createContext<IKeynuaDataContext>({
	packs: [],
	getPackById: () => undefined,
});
export { KeynuaDataContext };

/**
 * Helper function to get the useContext hook
 * already configured with the KeynuaDataContext
 */
export function useKeynuaDataContext(): IKeynuaDataContext {
	return useContext(KeynuaDataContext);
}

/**
 * KeynuaData provider to wrap pages
 */
export function KeynuaDataProvider({ children }: { children?: any }) {
	const data = useStaticQuery(graphql`
		query {
			keynuaData {
				packs {
					credits
					id
					name
					price
				}
			}
		}
	`);

	const packs: IPack[] = data.keynuaData.packs;
	return (
		<KeynuaDataContext.Provider
			value={{
				packs,
				getPackById: (id: string) => {
					return packs.find((pack: IPack) => pack.id === id);
				},
			}}
		>
			{children}
		</KeynuaDataContext.Provider>
	);
}
