import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

/**
 * The LanguageContext
 */
export interface ILanguageContext {
	/**
	 * The current language
	 */
	lang: "es" | "en" | "pt";
	/**
	 * All of the available languages indexed
	 * by id
	 */
	languages: {
		[language: string]: {
			id: string;
			prefix: string;
			label: string;
			pageTitle: string;
			shortLabel: string;
		};
	};
}

/**
 * Language context so that we can have the current
 * language and supported languages anywhere in the application
 */
const LanguageContext = React.createContext<ILanguageContext>({
	lang: "es",
	languages: {},
});
export { LanguageContext };

/**
 * Helper function to get the useContext hook
 * already configured with the LanguageContext
 */
export function useLanguageContext(): ILanguageContext {
	return useContext(LanguageContext);
}

/**
 * Language provider to wrap pages
 *
 * @param param0
 */
export function LanguageProvider({ lang, children }: { lang: "es" | "en" | "pt"; children?: any }) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					languages {
						id
						label
						pageTitle
						prefix
						shortLabel
					}
				}
			}
		}
	`);
	const languages: ILanguageContext["languages"] = {};
	for (const language of data.site.siteMetadata.languages) {
		languages[language.id] = language;
	}
	if (!languages[lang || "es"]) {
		throw new Error(`Language '${lang}' is not supported`);
	}
	return <LanguageContext.Provider value={{ lang, languages }}>{children}</LanguageContext.Provider>;
}
